
import { Navbar, Container, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
const Navigation = () => {

  return (
    <div className='nav1'>
      <Navbar bg="light" expand="md">
        <Container fluid>
          <Navbar.Brand href="#home" className='fw-dark fs-2'>Logo</Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Nav>
              <Nav.Link> <Link to="/" className='navbarlink '>DashBoard</Link></Nav.Link>
              <Nav.Link> <Link to="/mint" className='navbarlink'>Mint</Link></Nav.Link>
              <Nav.Link> <Link to="/reedem" className='navbarlink'>Redeem</Link></Nav.Link>
              <Nav.Link> <Link to='#' className='navbarlink'>Docs</Link></Nav.Link>
              <Nav.Link> <Link to='#' className='navbarlink'>About</Link></Nav.Link>
              <Nav.Link> <Link to='#' className='navbarlink'>Contacts</Link></Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  )
}

export default Navigation;