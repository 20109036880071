import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Mint from './Components/Mint';
import Reedem from './Components/Reedem';
import { HashRouter, Route, Routes } from "react-router-dom";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement);
root.render(

  <HashRouter basename="/">
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/mint" element={<Mint />} />
      <Route path="/reedem" element={<Reedem />} />
    </Routes>
  </HashRouter>

);


