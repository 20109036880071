import React from 'react';
import '../../src/App.css'


const LoadingSpinner = () => {
    return (
        <div className='loading'>
            <div className='loading_spinner'>
                <div className="lds-facebook"><div></div><div></div><div></div></div>

            </div>
        </div>
    )
}

export default LoadingSpinner