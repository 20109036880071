import React, { useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const Chart = (props: HighchartsReact.Props) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    let a: number = 0;
    let b: number = Number(props.strikeValue);
    let e: string = props.riskValue;
    let f: number = props.riskValue.length;
    let h: number = Number(e.substring(4, f - 1));
    if (h != 0) {
        var c: number = Number(props.strikeValue) + Number(h);
    } else {
        c = Number(props.strikeValue) + Number(props.riskValue)
    }

    let d: number = Number(c)


    const options: Highcharts.Options = {
        title: {
            text: "PREVIEW"
        },
        yAxis: {
            title: {
                text: `Token holder`
            },
            tickInterval: Number(props.riskValue),
            categories: ['0', String(props.riskValue)],
            accessibility: {
                rangeDescription: `Range:0 to ${Number(props.riskValue)}`
            }
        },
        xAxis: {
            title: {
                text: `${Number(props.currentRefPrice.toFixed(0))} <br>(current)`
            },
            tickInterval: 1,
            categories: [String(b), String(d)],
        },
        series: [{
            type: 'line',
            data: [0, Number(props.riskValue)]
        }]
    };
    if (props.strikeValue && props.strikeValue && props.riskValue && props.currentRefPrice) {
        return (

            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                ref={chartComponentRef}
                {...props}
            />
        );
    }

    return (
        <HighchartsReact />
    );
};

export default Chart;