
import './mint-reedem.css'
import Navigation from './Navigation';
import Chart from './Chart'
import { ethers, BigNumber } from 'ethers';
import { ABIS } from '../helper_functions/abis';
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import LoadingSpinner from './LoadingSpinner';

var cryptoDate = 'def';
var crptaction = localStorage.getItem('cyptAction');
var crptrisk = localStorage.getItem('rValuedata');
var crptstrike = localStorage.getItem('cyptStrike')
var crntdate = localStorage.getItem('cyptDate')

const Mint = (props: any) => {
    const [loading, setLoading] = useState(true);
    const [defaultCurrency, setDefaultCurrency] = useState('BTC');
    const [dates, setDates] = useState([]);
    const [currentRefPrice, setCurrentRefPrice] = useState(0);
    const [timeRemaining, setTimeRemaining] = useState({ days: '', hours: '', minutes: '' });
    const [rvalue, setRvalue] = useState([]);
    const [tableData, setTableData] = useState({});
    const [spinnerLoading, setSpinnerLoading] = useState(false);

    const [tableValue, setTableValue] = useState("")
    const [strikeValue, setStrikeValue] = useState(crptstrike)
    const [riskValue, setRiskValue] = useState(crptrisk)
    const [numberInput, setNumberInput] = useState('')
    const [currentDate, setCurrentDate] = useState(crntdate);
    const [currentCurrencyName, setCurrentCurrencyName] = useState(localStorage.getItem('cypt'))
    const [actionData, setActionData] = useState(crptaction)


    const testerAddress = "0xF4A2BC48Aa4cB0f0eFA84391aeCf0c3C8F4Ce845";
    const JSON_RPC_PROVIDER = 'https://rpc.ankr.com/polygon_mumbai';

    let provider = new ethers.providers.JsonRpcProvider(JSON_RPC_PROVIDER);
    let tester = new ethers.Contract(testerAddress, ABIS.tester, provider);
    const ASSETS = {
        BTC: "BTC", ETH: "ETH", LTC: "LTC"
    }

    const CALL_PUT_SELECTION = {
        CALL: "CALL", PUT: "PUT"
    }

    interface RowObject {
        nR3Short: number; nR3Long: number; nR2Short: number; nR2Long: number; nR1Short: number; nR1Long: number;
        pR1Short: number; pR1Long: number; pR2Short: number; pR2Long: number; pR3Short: number; pR3Long: number;
    }
    interface TimeRemainingObject {
        days: number, hours: number, minutes: number
    }

    async function getDates(asset: string) {
        return tester.getDates(asset)
    }
    async function getReferencePrice(asset: string) {
        return tester.refPrice(asset)
    }
    async function getRValues(asset: string) {
        return tester.getRValues(asset)
    }
    async function getRanges(asset: string) {
        return tester.getRanges(asset)
    }
    async function getStrikeWithObjects(asset: string, date: string) {
        return tester.getStrikeWithObjects(asset, date)
    }
    function formatDecNumber(decNumber: BigNumber) {
        return Number(ethers.utils.formatUnits(decNumber, 6))
    }
    function formatStrikeWithObjects(strikes: number[], rowObjects: BigNumber[][]) {
        let returnObj: { [id: number]: RowObject } = {}
        for (let i = 0; i < strikes.length; i++) {
            const thisStrike: number = strikes[i]
            const thisObject: BigNumber[] = rowObjects[i]
            returnObj[thisStrike] = {
                nR3Short: formatDecNumber(thisObject[0]),
                nR3Long: formatDecNumber(thisObject[1]),
                nR2Short: formatDecNumber(thisObject[2]),
                nR2Long: formatDecNumber(thisObject[3]),
                nR1Short: formatDecNumber(thisObject[4]),
                nR1Long: formatDecNumber(thisObject[5]),
                pR1Short: formatDecNumber(thisObject[6]),
                pR1Long: formatDecNumber(thisObject[7]),
                pR2Short: formatDecNumber(thisObject[8]),
                pR2Long: formatDecNumber(thisObject[9]),
                pR3Short: formatDecNumber(thisObject[10]),
                pR3Long: formatDecNumber(thisObject[11]),
            }
        }

        return returnObj
    }

    const setFilterData = async (cryptCurrencyName: any, dateIndexValue: string) => {
        setSpinnerLoading(true)

        await Promise.all([
            getDates(cryptCurrencyName),
            getRValues(cryptCurrencyName),
            getRanges(cryptCurrencyName),
            getReferencePrice(cryptCurrencyName),
            getTimeRemaining(cryptCurrencyName),


        ])
            .then(
                async results => {
                    if (cryptCurrencyName) {
                        cryptoDate = cryptCurrencyName
                    }
                    setDates(results[0])
                    let btcDates = results[0]
                    var dateIndex = 0;
                    if (dateIndexValue) {
                        for (var i = 0; i < btcDates.length; i++) {
                            if (btcDates[i] == dateIndexValue) {
                                dateIndex = i;
                            }
                        }
                    }
                    let ethRValues = results[1].map((x: BigNumber) => x.toNumber())
                    let btcRange = results[2].map((x: BigNumber) => x.toNumber())
                    const refPrice = formatDecNumber(results[3])
                    // const maxAmount = formatDecNumber(results[4])
                    const timeRemaining = getTimeRemaining(btcDates[dateIndex])
                    const btcStrikeWithObjects = await getStrikeWithObjects(cryptCurrencyName, btcDates[dateIndex])
                    const oneTablePage = formatStrikeWithObjects(btcStrikeWithObjects[0], btcStrikeWithObjects[1])
                    setCurrentRefPrice(refPrice)
                    setTimeRemaining(timeRemaining as any)
                    setRvalue(ethRValues)
                    setTableData(oneTablePage)
                    setTableValue(cryptCurrencyName)
                    setCurrentDate(btcDates[dateIndex])
                    setCurrentCurrencyName(cryptCurrencyName)


                }
            )
        setSpinnerLoading(false)

    }
    let crptname = localStorage.getItem('cypt')
    useEffect(
        () => {
            let timer1 = setTimeout(() => setLoading(true), 1000);
            return () => {
                setFilterData(crptname, "")
                clearTimeout(timer1);

            };
        }, []);

    useEffect(() => {
        setFilterData(crptname, "")
    }, [])

    function getTimeRemaining(date: string) {
        const end = new Date(date)
        const start = new Date()
        const diff = end.getTime() - start.getTime()
        let days = diff % (86400000)
        let hours = days % (3600000)
        let mins = hours % (60000)

        mins = Math.round((hours - mins) / 60000)
        hours = Math.floor((days - hours) / 3600000)
        days = Math.floor((diff - days) / 86400000)
        const timeRemaining: TimeRemainingObject = {
            days: days, hours: hours, minutes: mins
        }
        return timeRemaining
    }

    function setFilterpcData(cyptAction: any) {
        setActionData(cyptAction)
        localStorage.setItem('cyptAction', cyptAction)
        if (localStorage.getItem('cyptAction') == 'PUT') {
            var riskdata = ['R1 (' + rvalue[0] + ')', 'R2 (' + rvalue[1] + ')', 'R3 (' + rvalue[2] + ')']
        } else {
            var riskdata = ['R1 (' + rvalue[3] + ')', 'R2 (' + rvalue[4] + ')', 'R3 (' + rvalue[5] + ')']
        }

    }

    const strikedata = Object.keys(tableData)



    if (localStorage.getItem('cyptAction') == 'PUT') {
        var riskdata = ['R1 (' + rvalue[0] + ')', 'R2 (' + rvalue[1] + ')', 'R3 (' + rvalue[2] + ')']
    } else {
        var riskdata = ['R1 (' + rvalue[3] + ')', 'R2 (' + rvalue[4] + ')', 'R3 (' + rvalue[5] + ')']
    }

    if (crptaction && crptrisk && crptstrike && crntdate) {

        return (
            <div>
                <Navigation />

                <Container fluid>
                    <div className='header'>
                        <div className='dropdown'>

                            <select value={props.cypt} className="form-select form-select-md" aria-label=".form-select-md example" name='setFilterData' onChange={(e) => setFilterData(e.target.value, '')} >
                                <option selected hidden>{localStorage.getItem('cypt')}</option>
                                <option value={ASSETS.BTC}>{ASSETS.BTC}</option>
                                <option value={ASSETS.ETH}>{ASSETS.ETH}</option>
                                <option value={ASSETS.LTC}>{ASSETS.LTC}</option>
                            </select>
                        </div>
                        <div>
                            <select className="form-select form-select-md" aria-label=".form-select-md example" name='dates' onChange={(e) => {
                                setFilterData(cryptoDate, e.target.value)
                            }} >
                                {dates.map((value) => (
                                    (value == localStorage.getItem('cyptDate')) ? <option selected key={value} value={value}>{value}</option>
                                        : <option key={value} value={value}>{value}</option>
                                ))}
                            </select>
                        </div>

                        <div className='header-part'>

                            <button className='ml-4 btnrfrnw'>Refresh Now</button>

                        </div>
                        <div className='header-part'>
                            <div className=''><b>Current Reference Price</b></div>
                            <div>{currentRefPrice}</div>
                        </div>
                        <div className='header-part'>
                            <div className=''><b>Time To Expiry</b></div>
                            <div>{timeRemaining?.days} D {timeRemaining?.hours} H {timeRemaining?.minutes} M</div>
                        </div>
                        <div className='header-part'>
                            <button className='ml-4 cnctwltbtn'><b>CONNECT WALLET</b></button>
                        </div>
                    </div>

                    <div className='formsection'>
                        <div className="formsection-left">
                            <div className="frmsecleft">
                                <div className="frmsecleft-tx">
                                    <span className='textbg'>TYPE</span>
                                </div>
                                <div className="frmsecleft-drpdwn">
                                    <select className="form-select form-select-md" aria-label=".form-select-md example" onChange={(e) => setFilterpcData(e.target.value)}>
                                        <option selected hidden>{localStorage.getItem('cyptAction')}</option>
                                        <option value={CALL_PUT_SELECTION.CALL}>{CALL_PUT_SELECTION.CALL}</option>
                                        <option value={CALL_PUT_SELECTION.PUT}>{CALL_PUT_SELECTION.PUT}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="frmsecleft">
                                <div className="frmsecleft-tx">
                                    <span className='textbg'>STRIKE</span>
                                </div>
                                <div className="frmsecleft-drpdwn">
                                    <select onChange={(e) => setStrikeValue(e.target.value)} className="form-select form-select-md" aria-label=".form-select-md example">
                                        {strikedata.map((value) => (
                                            (value == localStorage.getItem('cyptStrike')) ? <option selected key={value} value={value}>{value}</option>
                                                : <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="frmsecleft">
                                <div className="frmsecleft-tx">
                                    <span className='textbg'>RISK</span>
                                </div>
                                <div className="frmsecleft-drpdwn">
                                    <select className="form-select form-select-md" aria-label=".form-select-md example" onChange={(e) => setRiskValue(e.target.value)}>
                                        {riskdata.map((value) => (
                                            (value == localStorage.getItem('riskType') + ' (' + localStorage.getItem('rValuedata') + ')') ?
                                                <option data-test={localStorage.getItem('riskType') + ' (' + localStorage.getItem('rValuedata') + ')'} selected key={value} value={value}>{value}</option>
                                                :
                                                <option data-test={localStorage.getItem('riskType') + ' (' + localStorage.getItem('rValuedata') + ')'} key={value} value={value}>{value}</option>
                                        ))}

                                    </select>
                                </div>
                            </div>
                            <div className="frmsecleft mt-5">

                                <div className="frmsecleft-tx">
                                    <span className="text-bg-2">Long Tokens <br />to Redeem</span>
                                </div>
                                <div className="formsection-right-sec-inp">
                                    <input className='inp-field-lngtoken' type='number' onChange={(e) => { setNumberInput(e.target.value) }}></input>
                                </div>

                            </div>
                            <div className="frmsecleft">
                                <div className="frmsecleft-dt">
                                    <div className="frmsecleft-dt-1 fw-light">
                                        You will Need to deposit
                                        <span className='h6'> 14,400 USDC </span>
                                    </div>
                                    <div className="frmsecleft-dt-2 fw-light">
                                        You will receieve <br />
                                        <span>{numberInput}<br /></span>
                                        <span>{currentCurrencyName} {currentDate} {actionData} {strikeValue} {riskValue}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="frmsecleft">
                                <div className="frmsecleft-btn-div-frm2">
                                    <button className="" type='button'>
                                        APPROVE/MINT
                                    </button>
                                </div>

                            </div>

                        </div>

                        <div className="formsection-right-chart">
                            <Chart tableValue={tableValue} riskValue={riskValue} strikeValue={strikeValue} currentRefPrice={currentRefPrice} />
                        </div>
                    </div>
                </Container >
                {spinnerLoading ? <LoadingSpinner /> : null}
            </div >
        )
    }
    return (
        <></>
    )

}

export default Mint;