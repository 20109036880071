
import Navigation from './Navigation';

import './mint-reedem.css';
import '../App.css'
import { ethers, BigNumber } from 'ethers';
import { ABIS } from '../helper_functions/abis';
import { ChangeEvent, useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import LoadingSpinner from './LoadingSpinner'

var delay = 5;

interface RowObject {
    nR3Short: number; nR3Long: number; nR2Short: number; nR2Long: number; nR1Short: number; nR1Long: number;
    pR1Short: number; pR1Long: number; pR2Short: number; pR2Long: number; pR3Short: number; pR3Long: number;
}

interface TimeRemainingObject {
    days: number, hours: number, minutes: number
}

var cryptoDate = 'def';
const Reedem = () => {
    const [defaultCurrency, setDefaultCurrency] = useState('BTC');
    const [dates, setDates] = useState([]);
    const [currentRefPrice, setCurrentRefPrice] = useState(0);
    const [timeRemaining, setTimeRemaining] = useState({ days: '', hours: '', minutes: '' });
    const [rvalue, setRvalue] = useState([]);
    const [tableData, setTableData] = useState({});
    const [loading, setLoading] = useState(true);
    const [filterpcdata, setFilterpcData] = useState('PUT');
    const [strikeValue, setStrikeValue] = useState("");
    const [riskValue, setRiskValue] = useState("");
    const [item17v, setItem17v] = useState("");
    const [item18v, setItem18v] = useState("");
    const [item20v, setItem20v] = useState("");
    const [item22v, setItem22v] = useState(" ")
    const [spinnerLoading, setSpinnerLoading] = useState(false)
    const [maxamout, setMaxamount] = useState(0)
    const [showAmountforLong, setShowAmountforLong] = useState('');
    const [showAmountforShort, setShowAmountforShort] = useState('')

    const testerAddress = "0xF4A2BC48Aa4cB0f0eFA84391aeCf0c3C8F4Ce845";
    const JSON_RPC_PROVIDER = 'https://rpc.ankr.com/polygon_mumbai';

    let provider = new ethers.providers.JsonRpcProvider(JSON_RPC_PROVIDER);
    let tester = new ethers.Contract(testerAddress, ABIS.tester, provider);

    enum STATE { STATE0, STATE1, STATE2 }
    enum R_TAG { NEG_R3, NEG_R2, NEG_R1, POS_R1, POS_R2, POS_R3 }


    const ASSETS = {
        BTC: "BTC", ETH: "ETH", LTC: "LTC"
    }
    const CALL_PUT_SELECTION = {
        CALL: "CALL", PUT: "PUT"
    }

    async function getDates(asset: string) {
        return tester.getDates(asset)
    }
    async function getReferencePrice(asset: string) {
        return tester.refPrice(asset)
    }
    async function getRValues(asset: string) {
        return tester.getRValues(asset)
    }
    async function getRanges(asset: string) {
        return tester.getRanges(asset)
    }
    async function getVersion(asset: string, date: string, strike: number, rId: R_TAG) {
        return tester.getVersion(asset, date, strike, rId)
    }
    // returns 2 arrays. One array of strike prices, and another array with the corresponding 12 values for the row.
    // use `formatStrikeWithObjects` to format.
    async function getStrikeWithObjects(asset: string, date: string) {
        return tester.getStrikeWithObjects(asset, date)
    }

    // returns an object with strikes and row objects as a key-value pair.
    function formatStrikeWithObjects(strikes: number[], rowObjects: BigNumber[][]) {
        let returnObj: { [id: number]: RowObject } = {}
        for (let i = 0; i < strikes.length; i++) {
            const thisStrike: number = strikes[i]
            const thisObject: BigNumber[] = rowObjects[i]
            returnObj[thisStrike] = {
                nR3Short: formatDecNumber(thisObject[0]),
                nR3Long: formatDecNumber(thisObject[1]),
                nR2Short: formatDecNumber(thisObject[2]),
                nR2Long: formatDecNumber(thisObject[3]),
                nR1Short: formatDecNumber(thisObject[4]),
                nR1Long: formatDecNumber(thisObject[5]),
                pR1Short: formatDecNumber(thisObject[6]),
                pR1Long: formatDecNumber(thisObject[7]),
                pR2Short: formatDecNumber(thisObject[8]),
                pR2Long: formatDecNumber(thisObject[9]),
                pR3Short: formatDecNumber(thisObject[10]),
                pR3Long: formatDecNumber(thisObject[11]),
            }
        }

        return returnObj
    }

    const setFilterData = async (cryptCurrencyName: string, dateIndexValue: string) => {
        setSpinnerLoading(true)
        await Promise.all([
            getDates(cryptCurrencyName),
            getRValues(cryptCurrencyName),
            getRanges(cryptCurrencyName),
            getReferencePrice(cryptCurrencyName),
            getMax(),
            getTimeRemaining(cryptCurrencyName)
        ]).then(
            async results => {
                if (cryptCurrencyName) {
                    cryptoDate = cryptCurrencyName;
                }
                setDates(results[0])
                let btcDates = results[0]
                var dateIndex = 0;
                if (dateIndexValue) {
                    for (var i = 0; i < btcDates.length; i++) {
                        if (btcDates[i] == dateIndexValue) {
                            dateIndex = i;
                        }
                    }
                }
                let ethRValues = results[1].map((x: BigNumber) => x.toNumber())
                let btcRange = results[2].map((x: BigNumber) => x.toNumber())
                const refPrice = formatDecNumber(results[3])
                const maxAmount = formatDecNumber(results[4])
                const timeRemaining = getTimeRemaining(btcDates[dateIndex])
                const btcStrikeWithObjects = await getStrikeWithObjects(cryptCurrencyName, btcDates[dateIndex])
                const oneTablePage = formatStrikeWithObjects(btcStrikeWithObjects[0], btcStrikeWithObjects[1])
                setCurrentRefPrice(refPrice)
                setTimeRemaining(timeRemaining as any)
                setRvalue(ethRValues)
                setTableData(oneTablePage)
                setMaxamount(maxAmount)
            })
        setSpinnerLoading(false)
        await Promise.all([
            item17(STATE.STATE0), item18(STATE.STATE1), item20(STATE.STATE2), item22(STATE.STATE0),
            getVersion(ASSETS.BTC, dates!![0], Number(Object.keys(tableData).at(0)), R_TAG.NEG_R1)
        ]).then(
            results => {
                setItem17v(results[0])
                setItem18v(results[1])
                setItem20v(results[2])
                setItem22v(results[3])
            }
        )
        setSpinnerLoading(false)
    }
    useEffect(
        () => {
            let timer1 = setTimeout(() => setLoading(true), delay * 1000);
            return () => {
                setFilterData(ASSETS.BTC, "")
                clearTimeout(timer1);
            };
        }, []);

    useEffect(() => {
        setFilterData("BTC", "")
    }, [])


    const handleMaxAmountForShort = () => {
        setShowAmountforShort(String(maxamout))
    }
    const handleMaxAmountForLong = () => {
        setShowAmountforLong(String(maxamout))
    }




    function getTimeRemaining(date: string) {
        const end = new Date(date)
        const start = new Date()
        const diff = end.getTime() - start.getTime()
        let days = diff % (86400000)
        let hours = days % (3600000)
        let mins = hours % (60000)

        mins = Math.round((hours - mins) / 60000)
        hours = Math.floor((days - hours) / 3600000)
        days = Math.floor((diff - days) / 86400000)
        const timeRemaining: TimeRemainingObject = {
            days: days, hours: hours, minutes: mins
        }
        return timeRemaining
    }

    function formatDecNumber(decNumber: BigNumber) {
        return Number(ethers.utils.formatUnits(decNumber, 6))
    }


    async function getMax() {
        return tester.maxAmount()
    }

    async function item17(state: STATE) {
        return tester.item17(state)
    }

    async function item18(state: STATE) {
        return tester.item18(state)
    }

    async function item20(state: STATE) {
        return tester.item20(state)
    }

    async function item22(state: STATE) {
        return tester.item22(state)
    }

    var fatchTableData: any;
    var filterTableData = false
    if (Object.keys(tableData).length > 0) {
        filterTableData = true
        fatchTableData = tableData
    }
    const strikedata = Object.keys(tableData)

    if (filterpcdata === 'CALL') {
        var riskdata = [rvalue[0], rvalue[1], rvalue[2]]
    } else {
        var riskdata = [rvalue[3], rvalue[4], rvalue[5]]
    }




    const changeAmtForLong = (e: ChangeEvent<HTMLInputElement>) => {
        setShowAmountforLong(e.currentTarget.value);
    };

    const changeAmtForShort = (e: ChangeEvent<HTMLInputElement>) => {
        setShowAmountforShort(e.currentTarget.value);
    }

    console.log(item22v)
    return (
        <div className='formone'>
            <Navigation />

            <Container fluid>
                <div className='header'>

                    <div className='dropdown'>
                        <select className="form-select form-select-md" aria-label=".form-select-md example" name='setFilterData'
                            onChange={(e) => setFilterData(e.target.value, '')}  >
                            <option value={ASSETS.BTC}>{ASSETS.BTC}</option>
                            <option value={ASSETS.ETH}>{ASSETS.ETH}</option>
                            <option value={ASSETS.LTC}>{ASSETS.LTC}</option>
                        </select>
                    </div>
                    <div>
                        <select className="form-select form-select-md" aria-label=".form-select-md example" name='dates' onChange={(e) => {
                            setFilterData(cryptoDate, e.target.value)

                        }} >
                            {dates.map((value) => (
                                <option key={value} value={value}>{value}</option>
                            ))}
                        </select>
                    </div>

                    <div className='header-part'>

                        <button className='ml-4 btnrfrnw'>Refresh Now</button>

                    </div>
                    <div className='header-part'>
                        <div className=''><b>Current Reference Price</b></div>
                        <div>{currentRefPrice}</div>
                    </div>
                    <div className='header-part'>
                        <div className=''><b>Time To Expiry</b></div>
                        <div>{timeRemaining?.days} D {timeRemaining?.hours} H {timeRemaining?.minutes} M</div>
                    </div>
                    <div className='header-part'>
                        <button className='ml-4 cnctwltbtn'><b>CONNECT WALLET</b></button>
                    </div>
                </div>


                <div className='formsection'>
                    <div className="formsection-left">
                        <div className="frmsecleft">
                            <div className="frmsecleft-tx">
                                <span className='textbg'>STRIKE</span>
                            </div>
                            <div className="frmsecleft-drpdwn">
                                <select className="form-select form-select-md" aria-label=".form-select-md example" onChange={(e) => setFilterpcData(e.target.value)}>

                                    <option value={CALL_PUT_SELECTION.CALL}>{CALL_PUT_SELECTION.CALL}</option>
                                    <option value={CALL_PUT_SELECTION.PUT}>{CALL_PUT_SELECTION.PUT}</option>
                                </select>
                            </div>
                        </div>
                        <div className="frmsecleft">
                            <div className="frmsecleft-tx">
                                <span className='textbg'>TYPE</span>
                            </div>
                            <div className="frmsecleft-drpdwn">
                                <select onChange={(e) => setStrikeValue(e.target.value)} className="form-select form-select-md" aria-label=".form-select-md example">
                                    {strikedata.map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="frmsecleft">
                            <div className="frmsecleft-tx">
                                <span className='textbg'>RISK</span>
                            </div>
                            <div className="frmsecleft-drpdwn">
                                <select className="form-select form-select-md" aria-label=".form-select-md example" onChange={(e) => setRiskValue(e.target.value)}>
                                    {riskdata.map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="frmsecleft">
                            <div className="frmsecleft-txprt">
                                <div className="frmsecleft-txprtdetails">

                                    <p className='mb-3 h6 fw-light'>
                                        {item17v}
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div className="frmsecleft">
                            <div className="expr-prc">
                                <div className="expr-prc-hd h5 fw-light">Expiry Price</div>
                                <div className="expr-prc-dt h5 fw-light">39000</div>
                            </div>
                        </div>
                        <div className="frmsecleft">
                            <div className="frmsecleft-btn-div">
                                <button className="" type='button'>
                                    SETTLE
                                </button>
                            </div>

                        </div>

                    </div>
                    <div className="formsection-right">
                        <div className="formsection-right-sec">
                            <div className="formsection-right-sec-1">
                                <div className="formsection-right-sec-hd">
                                    <h3>Redemption</h3>
                                    <hr />
                                </div>
                                <div className="formsection-right-sec-bd fw-light h6">
                                    {item18v}
                                </div>
                            </div>


                        </div>
                        <div className="formsection-right-sec">
                            <div className="formsection-right-sec-2">
                                <div className="formsection-right-sec-inphd  mx-5">
                                    Long Tokens <br />to Redeem
                                </div>
                                <div className="formsection-right-sec-inp" >
                                    <div className='mx-2 ' onClick={(e) => handleMaxAmountForLong()}>max</div>

                                    <input className='inp-field' type="number" onChange={changeAmtForLong} value={showAmountforLong} /></div>

                            </div>

                        </div>
                        <div className="formsection-right-sec">

                            <div className="formsection-right-sec-3">
                                <div>
                                    <p>{item20v}</p>
                                </div>
                            </div>
                        </div>
                        <div className="formsection-right-sec">
                            <div className='btn-div'>
                                <button type='button'>REDEEM LONG</button>
                            </div>
                        </div>
                        <div className="formsection-right-sec">
                            <div className="formsection-right-sec-2">
                                <div className="formsection-right-sec-inphd  mt-2 mx-5">
                                    Short Tokens <br />to Redeem
                                </div>
                                <div className="formsection-right-sec-inp">

                                    <div className=' mx-2' onClick={(e) => handleMaxAmountForShort()}>max</div>

                                    <input className='inp-field' type="number" onChange={changeAmtForShort} value={showAmountforShort} />
                                </div>
                            </div>
                        </div>
                        <div className="formsection-right-sec">
                            {item22v ? <div className="formsection-right-sec-3">
                                <div>
                                    <p><span className='fw-light '>{item22v}</span></p>
                                </div>
                            </div> : null}


                        </div>
                        <div className="formsection-right-sec">
                            <div className='btn-div'>
                                <button type='button'>REDEEM SHORT</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            {spinnerLoading ? <LoadingSpinner /> : null}
        </div >
    )
}

export default Reedem;