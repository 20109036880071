import React, { Suspense } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import LoadingSpinner from './Components/LoadingSpinner';

const Navigation = React.lazy(() => import('./Components/Navigation'));
const Banner = React.lazy(() => import('./Components/Banner'));
const Tableputcall = React.lazy(() => import('./Components/Tableputcall'));
function App() {
  return (
    <div className="App">

      <div>
        <Suspense fallback={<LoadingSpinner />}>
          <Navigation />
          <Banner />
          <Tableputcall />
        </Suspense>
      </div>

    </div>
  );
}

export default App;
